import React, { useState, useEffect, useRef, useContext } from 'react'
import axios from 'axios'
import { useLocation, useNavigate, Link } from 'react-router-dom'
import PlaceMap from '../components/Place/PlaceView/PlaceMap'
import DataSheet from '../components/Place/PlaceView/DataSheet'
import Images from '../components/Place/PlaceView/Images'
import Chart from '../components/Place/PlaceView/Chart'
import CheckedIn from '../components/Place/PlaceView/CheckedIn'
import BasedHere from '../components/Place/PlaceView/BasedHere'
import UserFeed from '../components/Place/PlaceView/UserFeed'
import countryList from 'react-select-country-list'
import { addComma, LinkTt } from '../src/helpers'
import UserContext from '../store/user-context'
import { placeIconPath, placeTypeName } from '../src/helpers'
import { ImgTag } from '../src/icons_lib'
import StarRating from '../components/Place/PlaceView/StarRating'

//import MetaTags from 'react-meta-tags';

const CollapseDiv = props => {
    return (
        <div className="accordion-item">
            <h2 className="accordion-header">
                <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target={'#' + props.elem_id}
                    aria-expanded="false"
                    aria-controls={props.elem_id}
                >
                    {props.title}

                    {props.sectCounter && props.sectCounter[props.elem_id] > 0 && (
                        <span className="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-primary">
                            {props.sectCounter[props.elem_id] > 0 &&
                                props.sectCounter[props.elem_id]}
                            <span className="visually-hidden">
                                counter value
                            </span>
                        </span>
                    )}
                </button>
            </h2>
            <div
                id={props.elem_id}
                className="accordion-collapse collapse"
                data-bs-parent="#subSections"
            >
                <div className="accordion-body">{props.children}</div>
            </div>
        </div>
    )
}

const PlaceView = () => {
    const [place, setPlace] = useState({})
    const [posts, setPosts] = useState([])
    const [sectCounter, setSectCounter] = useState({})
    const counterRef = useRef({})
    const navigate = useNavigate()

    const location = useLocation()
    const userCtx = useContext(UserContext)
    document.title = `Squidd.io ${place && placeTypeName(place)} - ${place &&
        place.name}`
    document.getElementsByTagName('meta')[
        'description'
    ].content = `Information about ${place &&
        place.name}. Satellite Maps, Address, Country, User Comments, Photos, Boats currently at location, Traffic, Vessel Types,  Weather Charts`

    useEffect(() => {
        //console.log('location: ', location)
        let place_id
        if (location.state) {
            // internal react request, already includes place object
            setPlace(location.state)
            place_id = location.state.id
        } else {
            // external request, need to retrieve place from API
            const pathname = location.pathname
            place_id = pathname.split('/').slice(-1)[0]
            const url_str = `/api/v1/places/${place_id}`
            axios
                .get(url_str)
                .then(resp => {
                    setPlace(resp.data)
                })
                .catch(resp => console.log('Error in retrieving place', resp))
        }

        // the posts state is shared among multiple components, hence the API call here. Normally it goes in the relevant comp
        const posts_url_str = `/api/v1/posts?place_id=${place_id}`
        axios
            .get(posts_url_str)
            .then(resp => {
                setPosts(resp.data)
            })
            .catch(resp => console.log('Error in retrieving posts', resp))
    }, [])

    const updCounter = (elem_id, value) => {
        counterRef.current = {
            ...counterRef.current,
            [elem_id]: (counterRef.current[elem_id] || 0) + value,
        }
        setSectCounter(counterRef.current)
    }
    const goToPlaceEdit = () => {
        navigate('/placeedit', { state: { place: place, id: place.id } })
    }

    return (
        <div>
            <div className="row">
                <div className="col-md-9 ps-4 mt-2">
                    <div className="to_left mt-1">
                        <Link to="/">
                            <LinkTt title="Back to Map">
                                <ImgTag icon_name="globe_arrow" size={40} />
                            </LinkTt>
                        </Link>
                    </div>

                    <div className="to_left ms-2">
                        <img
                            className="img-thumbnail"
                            src={placeIconPath(place)}
                            alt={placeTypeName(place)}
                        />
                    </div>
                    <h2 className="to_left ms-2">{place.name}</h2>
                </div>
                <div className="col-md-3 mt-2">
                    {addComma(placeTypeName(place))}
                    {addComma(place.city)}
                    {addComma(place.state_code)}
                    {place.country_code &&
                        countryList().getLabel(place.country_code)}
                </div>

            </div>

            <div className="row rounded bg-black my-3 mx-1">
                <div className="col-md-6 p-3">
                    <PlaceMap place={place}></PlaceMap>
                </div>
                <div className="col-md-6 p-3 mt-2">
                    <Images place={place} posts={posts}></Images>
                </div>
            </div>
            <StarRating place={place} />
            <div className="row">
                <div className="col-md-9 border rounded text-muted p-3 mx-2 my-2">
                    {place.description}
                </div>
                <div className="col-md-2  mt-2 text-center">
                    {userCtx.loggedIn && (
                        <button
                            id="edit-btn"
                            // disabled={userCtx.user.id != place.user_id}
                            onClick={goToPlaceEdit}
                            className="btn btn-primary btn-sm"
                        >
                            Edit information for this {placeTypeName(place)} 
                        </button>
                    )}
                    {userCtx.loggedIn && userCtx.user.id != place.user_id && (
                        <LinkTt
                            title="You must be the creator of the place to edit its contents. Add a review below if you are not the original creator"
                            id="id-1"
                        />
                    )}
                </div>

            </div>

            <div className="accordion" id="subSections">
                <CollapseDiv
                    title={placeTypeName(place) + ' address, coordinates etc.'}
                    elem_id="dataSheet"
                >
                    <div className="row">
                        <DataSheet place={place} />
                    </div>
                </CollapseDiv>

                <CollapseDiv
                    title="Traffic and weather charts"
                    elem_id="charts"
                    sectCounter={sectCounter}
                >
                    <div className="row">
                        <Chart
                            charts={place.charts}
                            callBack={updCounter}
                        ></Chart>
                    </div>
                </CollapseDiv>

                <CollapseDiv
                    title={
                        'Vessels based or currently moored at this ' +
                        placeTypeName(place)
                    }
                    elem_id="vessels"
                    sectCounter={sectCounter}
                >
                    <div className="row ">
                        <div className="col-md-6 col-lg-6">
                            <BasedHere
                                place={place}
                                callBack={updCounter}
                            ></BasedHere>
                        </div>
                        <div className="col-md-6 col-lg-6">
                            <CheckedIn
                                place={place}
                                callBack={updCounter}
                            ></CheckedIn>
                        </div>
                    </div>
                </CollapseDiv>

                <CollapseDiv
                    title="User reviews, photos, edits etc."
                    elem_id="feed"
                    sectCounter={sectCounter}
                >
                    <div className="row">
                        <UserFeed
                            place={place}
                            posts={posts}
                            callBack={updCounter}
                        ></UserFeed>
                    </div>
                </CollapseDiv>
            </div>
        </div>
    )
}

export default PlaceView
